var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      attrs: { "min-height": "5em", "min-width": "30em", "max-width": "50em" },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "py-1 pl-0 pr-5" },
        [
          _c(
            "v-timeline",
            { attrs: { dense: "", clipped: "" } },
            [
              _c(
                "v-slide-x-reverse-transition",
                { attrs: { group: "", "hide-on-leave": "" } },
                _vm._l(_vm.timelineEvents, function (taskEvent, i) {
                  return _c(
                    "div",
                    { key: i },
                    [
                      _c(
                        "v-timeline-item",
                        {
                          attrs: {
                            small: "",
                            color: _vm.util.taskStatusColor(taskEvent.Status),
                            icon: _vm.util.taskStatusIcom(taskEvent.Status),
                            "fill-dot": "",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                justify: "space-between",
                                align: "center",
                              },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: { cols: "4" },
                                },
                                [_vm._v(" " + _vm._s(taskEvent.Status) + " ")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "5" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatTimestamp(taskEvent.Timestamp)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("v-col", { staticClass: "text-right" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.timelineEvents[i + 1]
                                        ? ` ${_vm.util.formatDuration(
                                            taskEvent.Timestamp,
                                            _vm.timelineEvents[i + 1].Timestamp
                                          )} `
                                        : ""
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }