import moment from "moment";
import { getThingFiles, getDriveByID } from "@/js/signaloidClient";
import { DataSource, DataSourceType, DataSourceTypeE } from "@/types/api/dataSources";
import { FileTree } from "@/types/general";
import { monitoringCaptureError } from "@/plugins/monitoring";

// FIXME: this should use the
export function dataSourceIcon(dataSourceType: DataSourceType) {
	switch (dataSourceType) {
		case DataSourceTypeE.Gateway:
			return "mdi-cable-data";
		case DataSourceTypeE.Bucket:
			return "mdi-aws";
		case DataSourceTypeE.Drive:
			return "mdi-server-network";
		case DataSourceTypeE.SignaloidCloudStorage:
			return "mdi-folder-account-outline";
		// case "DataSource":
		// 	return "mdi-database-outline";
		default:
			return "";
	}
}

export function gatewayIPAddressString(gatewayData) {
	if (gatewayData?.ThingStatus?.local_ipv4_address) {
		return gatewayData.ThingStatus.local_ipv4_address;
	} else {
		// console.log("IP address not found. returning ??.??.??.??");
		return "??.??.??.??";
	}
}

export function gatewayIPKnown(gatewayData) {
	if (gatewayData?.ThingStatus?.local_ipv4_address) {
		return true;
	} else {
		return false;
	}
}

export function gatewayStatusIcon(gatewayStatus) {
	switch (gatewayStatus) {
		case "Disconnected":
			return { icon: "mdi-lan-disconnect", colour: "error" };
		case "Connected":
			return { icon: "mdi-lan-connect", colour: "success" };
		default:
			return { icon: "mdi-lan-pending", colour: "warning" };
	}
}

export function gatewayStatusFromLastUpdated(gatewayLastUpdated) {
	if (Number.isInteger(gatewayLastUpdated)) {
		const secondsSinceLastActive = Math.floor(Date.now() / 1000) - gatewayLastUpdated;

		if (secondsSinceLastActive >= 120) {
			return "Disconnected";
		}
		if (secondsSinceLastActive >= 30 && secondsSinceLastActive < 120) {
			return "Stale";
		} else {
			return "Connected";
		}
	}
	return "Disconnected";
}

export async function gatewayStatus(gatewayData) {
	if (gatewayData?.Presence?.status == "connected") {
		const secondsSinceLastActive = Math.floor(Date.now() / 1000) - (await gatewayLastUpdated(gatewayData));

		if (secondsSinceLastActive >= 120) {
			return "Disconnected";
		}
		if (secondsSinceLastActive >= 30 && secondsSinceLastActive < 120) {
			return "Stale";
		} else {
			return "Connected";
		}
	} else {
		return "Disconnected";
	}
}

export function parseGatewayFiles(dataFileList) {
	return dataFileList.map((file) => {
		return {
			name: file.file,
			path: file.file,
			updated_at: file.updated_at,
			type: "file",
			children: [],
		};
	});
}
export async function gatewayLastUpdated(gatewayData) {
	const lastPresenceUpdate = gatewayData?.Presence?.updated_at ?? 0;
	const lastConfigUpdate = gatewayData?.UpdatedAt ?? 0;
	let lastFileUpdate = 0;
	try {
		const resp = await getThingFiles(gatewayData.ThingID);
		const dataFileList = parseGatewayFiles(resp.data.items);
		dataFileList.sort((a, b) => {
			return b.updated_at - a.updated_at;
		});
		lastFileUpdate = dataFileList[0].updated_at;
	} catch (error) {
		monitoringCaptureError(error, "Fetch gateway last updated");
		lastFileUpdate = 0;
	}

	return Math.max(lastPresenceUpdate, lastFileUpdate);
}

export function timestampDayString(timestamp: number) {
	return moment(timestamp).calendar(null, {
		sameDay: "[Today]",
		nextDay: "[Tomorrow]",
		nextWeek: "dddd",
		lastDay: "[Yesterday]",
		lastWeek: "[Last] dddd",
		sameElse: "DD/MM/YYYY",
	});
}

export function formatDateDayString(msSinceEpoch: number) {
	if (msSinceEpoch > 0) {
		if (moment().diff(moment(msSinceEpoch), "days") > 7) {
			return `${timestampDayString(msSinceEpoch)}`;
		} else {
			return `${timestampDayString(msSinceEpoch)} at ${moment(msSinceEpoch).format("HH:mm:ss")}`;
		}
	} else {
		return "---";
	}
}

export function formatDateStandardString(msSinceEpoch: number) {
	if (msSinceEpoch > 0) {
		return moment.unix(msSinceEpoch).format("HH:mm:ss on DD MMM YYYY");
	} else {
		return "---";
	}
}

export async function formatDataSourcesPayload(
	ResourceID: string | undefined,
	ResourceType: DataSourceType | undefined,
	Location: string | undefined
): Promise<DataSource[]> {
	// TODO: add validation for the mount configs
	if (ResourceID && ResourceType && Location) {
		if (ResourceType === "Drive") {
			const resp = await getDriveByID(ResourceID);

			if (resp?.data?.DataSources) {
				// if (Location === ".") {
				// 	return resp.data.DataSources;
				// } else {
				return resp.data.DataSources.map((x) => {
					return {
						Object: "DataSource",
						ResourceID: x.ResourceID,
						ResourceType: x.ResourceType,
						Location: `${Location}/${x.Location}`,
					};
				});
				// }
			}
		} else {
			return [
				{
					Object: "DataSource",
					ResourceID: ResourceID,
					ResourceType: ResourceType,
					Location: Location,
				},
			];
		}
	}
	/*
	 *	Return empty list when the user does not specify any data source
	 */
	return [];
}

// WIP: doesn't work yet
// export function pathExistsInTree(path: string, tree: FilePath[]) {
// 	const pathSplitByDepth = path.split("/");
// 	console.warn("🚀 ~ file: utilities.ts:186 ~ pathExistsInTree ~ pathSplitByDepth:", pathSplitByDepth);
// 	if (pathSplitByDepth.length === 0) {
// 		return true;
// 	}

// 	for (let index = 0; index < tree.length; index++) {
// 		const element = tree[index];
// 		console.warn("🚀 ~ file: utilities.ts:193 ~ pathExistsInTree ~ element:", element);
// 		if (element.path === pathSplitByDepth[0]) {
// 			console.warn(
// 				"🚀 ~ file: utilities.ts:190 ~ pathExistsInTree ~ element.path:",
// 				element.path,
// 				pathSplitByDepth[0]
// 			);
// 			// path exists in sub tree
// 			console.log(
// 				"path exists in sub tree... calling fn with",
// 				pathSplitByDepth.slice(1).join("/"),
// 				element.children
// 			);
// 			// check if the rest of the paths exist in the child paths
// 			return pathExistsInTree(pathSplitByDepth.slice(1).join("/"), element.children);
// 		}
// 	}

// 	return false;
// }

// FIXME: what is the input type here?
export function parseSigCloudStorageFileList(dataFileList) {
	return dataFileList.map((file) => {
		const fileExt = file.file.split("/").slice(-1)[0].split(".").slice(-1)[0];
		return {
			name: file.file,
			path: file.file,
			type: fileExt ? fileExt : "dir",
		};
	});
}
function searchPathInTree(path: string, tree: FileTree[]) {
	return tree.findIndex((branch) => {
		return branch.path === path;
	});
}
function createPathSubtree(segmentedPath: string[]): FileTree {
	let children: FileTree[];
	let pathType;
	if (segmentedPath.length == 1) {
		// only one nesting level
		children = [];
		pathType = segmentedPath[0].split(".").slice(-1)[0];
	} else if (segmentedPath.length == 2) {
		// two nesting levels:
		// stop recursion if the last level is "/.directory"
		const lastLevel = segmentedPath.slice(-1)[0];
		if (lastLevel == "") {
			children = [];
			pathType = "dir";
		} else {
			children = [createPathSubtree(segmentedPath.slice(1))];
			pathType = "dir";
		}
	} else {
		children = [createPathSubtree(segmentedPath.slice(1))];
		pathType = "dir";
	}
	return {
		name: segmentedPath[0],
		path: segmentedPath[0],
		type: pathType,
		children: children,
	};
}

function expandChildPaths(parent: undefined | string, tree: FileTree[]) {
	return tree.map((branch) => {
		branch.path = parent ? `${parent}/${branch.path}` : branch.path;
		if (branch.children) {
			expandChildPaths(branch.path, branch.children);
		}
		return branch;
	});
}

export function pathListToDirectoryTree(pathList: string[]): FileTree[] {
	const tree: FileTree[] = [];
	pathList.forEach((file) => {
		if (file != "") {
			const segmentedPath = file.split("/");
			let subTree = tree;

			for (let subPathIdx = 0; subPathIdx < segmentedPath.length; subPathIdx++) {
				const subPath = segmentedPath[subPathIdx];
				const branchIndex = searchPathInTree(subPath, subTree);
				if (branchIndex >= 0) {
					subTree = subTree[branchIndex].children;
					continue;
				} else {
					subTree.push(createPathSubtree(segmentedPath.slice(subPathIdx)));
					break;
				}
			}
		}
	});
	return expandChildPaths(undefined, tree);
}

export function fileIcons(fileType: string) {
	switch (fileType.toLowerCase()) {
		case "c":
		case "h":
			return "mdi-language-c";
		case "cpp":
		case "c++":
		case "cc":
		case "cp":
		case "cxx":
			return "mdi-language-cpp";
		case "f":
		case "for":
		case "f77":
		case "ftn":
		case "f90":
		case "f95":
		case "f03":
		case "f08":
			return "mdi-language-fortran";
		case "py":
			return "mdi-language-python";
		case "html":
			return "mdi-language-html5";
		case "js":
			return "mdi-nodejs";
		case "json":
			return "mdi-code-json";
		case "md":
			return "mdi-language-markdown";
		case "pdf":
			return "mdi-file-pdf";
		case "png":
		case "jpg":
		case "jpeg":
			return "mdi-file-image";
		case "txt":
			return "mdi-file-document-outline";
		case "csv":
		case "xls":
			return "mdi-file-table-outline";
		case "zip":
		case "7z":
		case "std":
		case "gz":
		case "rar":
			return "mdi-file-cabinet";
		case "mov":
		case "mp4":
		case "m4v":
		case "avi":
		case "mkv":
			return "mdi-file-video-outline";
		default:
			return "mdi-file-document-outline";
	}
}
export function isTextFile(fileType: string) {
	switch (fileType.toLowerCase()) {
		case "c":
		case "h":
		case "cpp":
		case "c++":
		case "cc":
		case "cp":
		case "cxx":
		case "f":
		case "for":
		case "f77":
		case "ftn":
		case "f90":
		case "f95":
		case "f03":
		case "f08":
		case "py":
		case "html":
		case "js":
		case "json":
		case "md":
		case "txt":
		case "csv":
		case "tsv":
			return true;
		default:
			return false;
	}
}

export const awsS3RestrictedCharacters = ["\\", "^", "{", "}", "%", "`", "]", ">", "[", "~", "<", "#", "|"];
export const awsS3SpecialCharacters = ["&", "$", "@", "=", ";", ":", "+", ",", "?"];
export const awsS3MaxPathSizeBytes = 1024;

// eventually the function in /src/components/DataSources/SigCloudStorageFileList.vue will be replaced with this
export async function downloadFile(fileURL: string, fileName: string) {
	try {
		// based on https://www.delftstack.com/howto/javascript/javascript-download/
		if (fileURL) {
			const link = document.createElement("a");
			link.href = fileURL;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	} catch (error) {
		monitoringCaptureError(error, "Download file");
	}
}
