var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "mt-2",
      attrs: { width: "100%", "min-height": "36px", outlined: "" },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "px-sm-2 px-1 pr-2 font-weight-light text-body-2",
          attrs: {
            "no-gutters": "",
            justify: _vm.$vuetify.breakpoint.mobile ? "space-around" : "center",
            align: "center",
          },
        },
        [
          _c(
            "v-col",
            { staticClass: "mr-sm-2 mr-1", attrs: { cols: "5", sm: "auto" } },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    "no-gutters": "",
                    align: "center",
                    "align-content": "center",
                  },
                },
                [
                  _c(
                    "v-col",
                    {
                      class:
                        _vm.taskID !== undefined
                          ? "ma-0 pa-0 mb-n2 mb-sm-0 mt-1 mt-sm-0"
                          : "",
                      attrs: { cols: "12", sm: "auto" },
                    },
                    [_vm._v(" Task:" + _vm._s("\xa0") + " ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-0 pa-0 flex-nowrap",
                      attrs: { cols: "12", sm: "auto" },
                    },
                    [
                      _vm.taskID !== undefined
                        ? _c("TaskIDDisplay", {
                            attrs: {
                              taskID: _vm.taskID,
                              link: {
                                name: "TaskDetail",
                                params: { id: _vm.taskID },
                              },
                              showClipboardButton: true,
                            },
                          })
                        : _c(
                            "code",
                            {
                              staticClass:
                                "font-weight-regular my-3 align-center",
                              staticStyle: {
                                "font-size": "0.875em",
                                "min-width": "50px",
                                "min-height": "36px",
                              },
                            },
                            [_vm._v(" ---------- ")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "ml-sm-2 ml-1 py-2",
              attrs: { cols: "4", sm: "auto" },
            },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    align: "center",
                    "align-content": "center",
                    "no-gutters": "",
                  },
                },
                [
                  _c(
                    "v-col",
                    {
                      class:
                        _vm.taskStatus !== undefined
                          ? "ma-0 pa-0 mb-0 mb-sm-0 mt-n1 mt-sm-auto"
                          : "",
                      attrs: {
                        cols: "12",
                        sm: "auto",
                        "data-test": "project-status-label",
                      },
                    },
                    [_vm._v(" Status:" + _vm._s("\xa0") + " ")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-0 pa-0 flex-nowrap",
                      attrs: { cols: "12", sm: "auto" },
                    },
                    [
                      _c(
                        "span",
                        {
                          class: `font-weight-regular ${_vm.taskStatusColor}--text `,
                          attrs: { "data-test": "project-status-value" },
                        },
                        [_vm._v(_vm._s(_vm.taskStatus))]
                      ),
                      _vm.activeTaskStatus
                        ? _c("v-progress-linear", {
                            attrs: {
                              indeterminate: "",
                              height: "2",
                              color: _vm.taskStatusColor,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.taskAccessState?.error
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", "open-delay": "250" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mr-1",
                                            attrs: {
                                              size: "14",
                                              color: "warning",
                                            },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-alert-outline ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            311381781
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.taskAccessState.message)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.taskStatus && _vm.isTaskActive(_vm.taskStatus)
            ? _c(
                "v-col",
                { attrs: { cols: "1", sm: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-sm-3 ml-n1",
                      attrs: {
                        color: "secondary",
                        elevation: "0",
                        outlined: "",
                        height: _vm.$vuetify.breakpoint.mobile
                          ? "25px"
                          : "20px",
                        "x-small": "",
                      },
                      on: { click: _vm.cancelTask },
                    },
                    [
                      !_vm.$vuetify.breakpoint.mobile
                        ? [_vm._v(" Cancel ")]
                        : _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-cancel "),
                          ]),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.taskStatusMessageOnError
        ? _c(
            "v-row",
            {
              staticClass: "px-sm-2 px-1 pr-2 font-weight-light text-body-2",
              attrs: {
                "no-gutters": "",
                justify: _vm.$vuetify.breakpoint.mobile
                  ? "space-around"
                  : "center",
                align: "center",
              },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "ml-sm-2 ml-1 py-2",
                  attrs: { cols: "8", sm: "auto" },
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        align: "center",
                        "align-content": "center",
                        "no-gutters": "",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "ma-0 pa-0 mb-0 mb-sm-0 mt-n1 mt-sm-auto",
                          attrs: { cols: "12", sm: "auto" },
                        },
                        [_vm._v(" Error:" + _vm._s("\xa0") + " ")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pa-0 flex-nowrap",
                          attrs: { cols: "12", sm: "auto" },
                        },
                        [
                          _c(
                            "code",
                            {
                              staticClass:
                                "font-weight-light my-3 align-center",
                              staticStyle: {
                                "font-size": "0.875em",
                                "min-width": "50px",
                                "min-height": "36px",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.taskStatusMessageOnError) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }