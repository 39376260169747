
import { PropType, defineComponent } from "vue";

// Types
import {
	TaskStatusE,
	Task,
	isPipelineTask,
	isRepositoryTask,
	isSourceCodeTask,
	TaskTypeE,
	isTaskActive,
	TaskType,
	isTaskFail,
} from "@/types/api/tasks";

// Libraries
import moment from "moment";

// Utilities
import * as signaloidClient from "@/js/signaloidClient";
import { repoFullNameFromUrl } from "@/js/utilities";
import * as util from "@/js/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Components
import TooltipButton from "@/components/Common/TooltipButton.vue";
import CopyToClipboardButton from "@/components/Common/CopyToClipboardButton.vue";
import ActiveCoreTooltip from "@/components/Repositories/ActiveCoreTooltip.vue";
import TaskStatusTransitionTooltip from "@/components/TaskManager/TaskStatusTransitionTooltip.vue";
import TasksRemoveDialog from "@/components/TaskManager/TaskRemoveDialog.vue";

// Stores
import { useTasksStore } from "@/stores/tasks";
import { useRootStore } from "@/stores/root";
import { Core } from "@/types/api/cores";

// Global variables
const AsTimeStamp_19900101 = 631152000;

type ComponentData = {
	remoteError: boolean;
	remoteErrorMessage: string | undefined;
	removeTaskDialog: boolean;
};

export default defineComponent({
	name: "TaskCard",
	components: {
		TooltipButton,
		CopyToClipboardButton,
		ActiveCoreTooltip,
		TaskStatusTransitionTooltip,
		TasksRemoveDialog,
	},
	props: {
		taskID: { type: String, required: true },
		taskData: { type: Object as PropType<Task>, required: false },
		showDeleteTaskButton: { type: Boolean, default: false },
	},
	data: (): ComponentData => ({
		remoteError: false,
		remoteErrorMessage: undefined,
		removeTaskDialog: false,
	}),
	setup() {
		const tasksStore = useTasksStore();
		const rootStore = useRootStore();

		return {
			tasksStore,
			rootStore,
			TaskStatusE,
			isPipelineTask,
			isRepositoryTask,
			isSourceCodeTask,
			repoFullNameFromUrl,
			isTaskActive,
			util,
		};
	},
	methods: {
		async cancelActiveTask() {
			if (this.taskData?.TaskID) {
				try {
					await signaloidClient.cancelTaskByID(this.taskData.TaskID);
				} catch (error) {
					monitoringCaptureError(error, "Cancel active task");
				}
			}
		},

		secondsSinceEpochToDateString(timestamp: number) {
			//moment requires timestamp in milliseconds
			return moment(timestamp * 1000).calendar(null, {
				sameDay: "[Today]",
				nextDay: "[Tomorrow]",
				nextWeek: "dddd",
				lastDay: "[Yesterday]",
				lastWeek: "[Last] dddd",
				sameElse: "DD/MM/YYYY",
			});
		},
		openTaskDetails() {
			//@ts-ignore
			this.$posthog?.capture("show_task_details_button_clicked");

			// @ts-ignore FIXME: For some reason TS does not like this redirect
			this.$router.push({
				name: "TaskDetail",
				params: { id: this.taskData?.TaskID, initialTaskData: this.taskData },
			});
		},
		openRemoveTaskDialog() {
			this.removeTaskDialog = true;
		},
		closeRemoveTaskDialog() {
			this.removeTaskDialog = false;
		},
		removeTaskSuccess() {
			//@ts-ignore
			this.$posthog?.capture("task_metadata_deleted");
			this.$emit("removeTaskSuccess");
		},
	},
	computed: {
		taskStatusMessageOnError: function (): string | null {
			if (this.taskData && !isTaskFail(this.taskData.Status)) {
				return null;
			}
			const statusTransition =
				this.taskData && this.taskData.StatusTransitions.filter((st) => st.Status == this.taskData?.Status)[0];

			// Kea-cloud errors are formatted as `ErrXXX: Error message.`, where `ErrXXX` is an enum-like identifier.
			// This code checks if the message contains a colon, splits it, and returns the error message without the prefix.
			if (statusTransition && statusTransition.Message?.includes(":")) {
				const messageParts = statusTransition.Message.split(":");
				return messageParts.slice(1).join(":").trim() || null;
			}
			return statusTransition?.Message ?? null;
		},
		taskType: function (): TaskType | string {
			return this.taskData?.Application.Type ?? "Unknown";
		},
		runDuration: function (): string {
			const secondsOnly = true;

			if (!this.taskData || !this.taskData?.StartedAt || !this.taskData?.FinishedAt) {
				return "---";
			}

			if (this.taskData.StartedAt <= AsTimeStamp_19900101 || this.taskData?.FinishedAt <= AsTimeStamp_19900101) {
				return "---";
			}

			const finishTime = moment(this.taskData.FinishedAt * 1000);
			const createTime = moment(this.taskData.CreatedAt * 1000);
			const startTime = moment(this.taskData.StartedAt * 1000);

			if (finishTime.isValid() && startTime.isValid()) {
				const duration = finishTime.diff(startTime);
				if (duration < 1000) {
					//less than a second
					return secondsOnly ? "< 1s" : `${duration}ms`;
				} else if (duration < 60000) {
					//less than a minute
					return `${Math.floor(duration / 1000)}s` + (secondsOnly ? "" : ` ${duration % 1000}ms`);
				} else {
					return `${Math.floor(duration / 60000)}m ${Math.floor((duration % 60000) / 1000)}s`;
				}
			} else {
				return "---";
			}
		},
		executionTimeString: function (): string {
			if (!this.taskData || !this.taskData?.Stats || !this.taskData?.Stats.ProcessorTime) {
				return "";
			}

			return util.usDurationToString(this.taskData?.Stats.ProcessorTime * 1000_000);
		},
		dynamicInstructions: function (): string {
			if (!this.taskData || !this.taskData?.Stats || !this.taskData?.Stats.DynamicInstructions) {
				return "";
			}

			return util.toStringWithMagnitude(this.taskData.Stats.DynamicInstructions);
		},
		startTimestamp: function (): string {
			if (!this.taskData?.StartedAt || this.taskData.StartedAt <= AsTimeStamp_19900101) {
				return "---";
			}
			return `${this.secondsSinceEpochToDateString(this.taskData.StartedAt)}, ${moment(
				this.taskData.StartedAt * 1000
			).format("HH:mm:ss")}`;
		},
		createdTimestamp: function (): string {
			if (!this.taskData?.CreatedAt || this.taskData.CreatedAt <= AsTimeStamp_19900101) {
				return "---";
			}
			return `${this.secondsSinceEpochToDateString(this.taskData.CreatedAt)}, ${moment(
				this.taskData.CreatedAt * 1000
			).format("HH:mm:ss")}`;
		},
		endTimestamp: function (): string {
			if (!this.taskData) {
				return "---";
			}
			if (moment(this.taskData.StartedAt * 1000).isSame(this.taskData.FinishedAt * 1000, "day")) {
				return moment(this.taskData.FinishedAt * 1000).format("HH:mm:ss");
			} else {
				return `${this.secondsSinceEpochToDateString(this.taskData.FinishedAt)}, ${moment(
					this.taskData.FinishedAt * 1000
				).format("HH:mm:ss A")}`;
			}
		},
		projectSourceIcon: function (): string {
			return util.projectSourceIcon(this.taskType);
		},
		inTaskDetail(): boolean {
			return this.$route.name == "TaskDetail";
		},
		taskCore(): Core | undefined {
			if (!this.taskData) {
				return undefined;
			}

			let core;

			if (isSourceCodeTask(this.taskData)) {
				core = this.taskData.Application.Overrides.Core;
			} else if (isRepositoryTask(this.taskData)) {
				core = this.taskData.Application.Overrides?.Core ?? this.taskData.Application.Repository.Core;
			} else if (isPipelineTask(this.taskData)) {
				core = this.taskData.Application.Pipeline.BaseCore.Name;
			}

			return core;
		},
		taskCoreName(): string | undefined {
			if (!this.taskCore) {
				return undefined;
			}

			return this.taskCore.Name;
		},
		taskArguments(): string | undefined {
			if (!this.taskData) {
				return undefined;
			}

			switch (this.taskData.Application.Type) {
				case TaskTypeE.Repository:
					return (
						this.taskData.Application.Overrides?.Arguments ?? this.taskData.Application.Repository.Arguments
					);

				case TaskTypeE.SourceCode:
					return (
						this.taskData.Application.Overrides.Arguments ?? this.taskData.Application.SourceCode.Arguments
					);

				case TaskTypeE.Pipeline:
					/*
					 *	Pipelines have multiple nodes that each could have different arguments. Unclear what to show here.
					 */
					return undefined;

				case TaskTypeE.Module:
					return (
						this.taskData.Application?.Overrides?.Arguments ??
						this.taskData.Application.Overrides?.Arguments ??
						this.taskData.Application.Module.Arguments ??
						this.taskData.Application.Module.Source.Overrides?.Arguments ??
						(this.taskData.Application.Module.Source.Type === TaskTypeE.Repository
							? this.taskData.Application.Module.Source.Repository.Arguments
							: undefined) ??
						(this.taskData.Application.Module.Source.Type === TaskTypeE.SourceCode
							? this.taskData.Application.Module.Source.SourceCode.Arguments
							: undefined)
					);
				default:
					return undefined;
			}
		},
	},
});
