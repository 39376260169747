
import { defineComponent, PropType } from "vue";

// Types
import { isTaskActive, isTaskFail, Task, TaskStatus, TaskStatusE } from "@/types/api/tasks";

// Utilities
import * as util from "@/js/utilities";

// Components
import TaskIDDisplay from "@/components/TaskIDDisplay.vue";
import { RemoteAccessState } from "@/types/general";

export default defineComponent({
	name: "BuildBar",
	components: {
		TaskIDDisplay,
	},
	props: {
		taskID: { type: String },
		taskStatus: { type: String as PropType<TaskStatus> },
		taskAccessState: { type: Object as PropType<RemoteAccessState> },
		activeTask: { type: Object as PropType<undefined | Partial<Task>> },
	},
	setup() {
		return { isTaskActive, TaskStatusE };
	},
	computed: {
		taskStatusColor(): string {
			return util.taskStatusColor(this.taskStatus);
		},
		activeTaskStatus(): boolean {
			return this.taskStatus ? isTaskActive(this.taskStatus) : false;
		},
		taskStatusMessageOnError: function (): string | null {
			if (this.activeTask?.Status && !isTaskFail(this.activeTask.Status)) {
				return null;
			}
			const statusTransition =
				this.activeTask?.StatusTransitions &&
				this.activeTask.StatusTransitions.filter((st) => st.Status == this.activeTask?.Status)[0];

			// Kea-cloud errors are formatted as `ErrXXX: Error message.`, where `ErrXXX` is an enum-like identifier.
			// This code checks if the message contains a colon, splits it, and returns the error message without the prefix.
			if (statusTransition && statusTransition.Message?.includes(":")) {
				const messageParts = statusTransition.Message.split(":");
				return messageParts.slice(1).join(":").trim() || null;
			}

			return statusTransition?.Message ?? null;
		},
	},
	methods: {
		cancelTask() {
			this.$emit("cancel-task");
		},
	},
});
