
import { defineComponent, PropType } from "vue";

// Types
import { DataSource, DataSourceTypeE, isLocalMountConfig, LocalMountConfig } from "@/types/api/dataSources";

// Libraries

// Utilities
import * as dsUtil from "@/components/DataSources/utilities";
import * as util from "@/js/utilities";

// Components
import EditorControlBar from "@/components/DevelopmentPlatform/EditorControlBar.vue";
import MonacoEditor, { kDefaultDarkTheme, kDefaultTheme } from "@/components/MonacoEditor.vue";
import RunArgumentsDialog from "@/components/RunArgumentsDialog.vue";
import SelectCoreDialog from "@/components/SelectCoreDialog.vue";
import MountDataSourcesDialog from "@/components/MountDataSourcesDialog.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";
import LoadableChip from "@/components/Common/LoadableChip.vue";

// Stores
import { mapState } from "pinia";
import { useCoresStore } from "@/stores/cores";
import { useEditorStore } from "@/stores/editor";
import { useRootStore } from "@/stores/root";
import { SourceCodeLanguageObject } from "@/types/api/sourceCode";
import { Task, TaskStatus } from "@/types/api/tasks";
import { RemoteAccessState } from "@/types/general";
import { Core } from "@/types/api/cores";

// Global variables

// Local Types
type ComponentData = {
	codeBuffer: string;
	monaco: boolean;
	selectedCodeLanguage: undefined | SourceCodeLanguageObject;
	showRunArgumentsDialog: boolean;
	showSelectCoreDialog: boolean;
	showMountDataSourcesDialog: boolean;
	showEditor: boolean;
};

export default defineComponent({
	name: "Editor",
	components: {
		MonacoEditor,
		RunArgumentsDialog,
		SelectCoreDialog,
		MountDataSourcesDialog,
		EditorControlBar,
		TooltipButton,
		LoadableChip,
	},
	props: {
		taskID: { type: String },
		taskStatus: { type: String as PropType<TaskStatus> },
		editorLayout: { type: String, default: "vertical" },
		editorHeight: { type: String, default: "60%" },
		editorWidth: { type: String, default: "100%" },
		variableViewerVisibility: { type: Boolean, default: false },
		disableVariableViewer: { type: Boolean, default: false },
		disableLayoutChange: { type: Boolean, default: false },
		foldable: { type: Boolean, default: false },
		activeTask: { type: Object as PropType<undefined | Partial<Task>> },
		taskAccessState: { type: Object as PropType<RemoteAccessState> },
	},
	data: (): ComponentData => ({
		codeBuffer: "",
		monaco: true,
		selectedCodeLanguage: undefined,
		showRunArgumentsDialog: false,
		showSelectCoreDialog: false,
		showMountDataSourcesDialog: false,
		showEditor: true,
	}),
	setup() {
		const coresStore = useCoresStore();
		const editorStore = useEditorStore();
		return { coresStore, editorStore, isLocalMountConfig };
	},
	expose: ["getCode"],
	methods: {
		getCode() {
			// This function is used by components that use this component
			return this.codeBuffer;
		},
		getSelectedLanguage() {
			return this.selectedCodeLanguage;
		},
		getActiveCore() {
			// This function is used by components that use this component
			return this.editorCore;
		},
		setActiveCoreFromDialog(CoreID) {
			// Used for selecting active core from cores dialog
			this.closeSelectCoreDialog();
			this.editorStore.setEditorCore(CoreID, true);
		},
		setActiveCore(chosenCore: Core) {
			this.editorStore.setEditorCore(chosenCore?.CoreID, true);
		},
		switchToReferenceCore() {
			this.setActiveCore(this.coresStore.defaultReferenceCore);
		},
		setCodeLanguage(codeLanguage: SourceCodeLanguageObject) {
			this.selectedCodeLanguage = codeLanguage;
			// localStorage.setItem("editor-language", JSON.stringify(this.selectedCodeLanguage));
		},
		saveChange() {
			// This saves the code to local storage on every update
			this.$emit("code-changed");
		},
		checkForCodeChangesAndSync(lastCode = "") {
			// TODO: might be worth doing this at the store level but using all of local storage
			if (lastCode !== this.codeBuffer) {
				this.editorStore.setEditorSourceCode(this.codeBuffer, true);
			}
			const currentCode = this.codeBuffer;
			setTimeout(() => {
				this.checkForCodeChangesAndSync(currentCode);
			}, this.editorStore.codeSyncFrequency);
		},

		requestFullscreen() {
			if (this.monaco) {
				// @ts-ignore FIXME:
				this.$refs.monacoEditor.goFullscreen();
			} else {
				// @ts-ignore FIXME:
				this.cmOptions.fullScreen = true;
			}
		},
		async resetCodeToDefault(){
			await this.editorStore.resetEditorSourceCode()
			this.codeBuffer = this.editorCode as string;
			this.checkForCodeChangesAndSync(this.editorCode);
		},	
		toggleEditorWhiteTheme() {
			this.editorStore.setEditorTheme(kDefaultTheme, false);
		},		
		toggleEditorDarkTheme() {
			this.editorStore.setEditorTheme(kDefaultDarkTheme, false);
		},	
		toggleEditorPreferredTheme() {
			this.editorStore.setToPreferredTheme();
		},
		toggleEditorLayout() {
			this.editorStore.toggleEditorLayout(true);
		},
		toggleVariableViewerVisibility() {
			this.editorStore.toggleVariableViewerVisibility(true);
		},

		//* run arguments
		getRunArguments() {
			return this.runArguments;
		},
		setRunArguments(data: { runArguments: string }) {
			this.editorStore.setEditorArgs(data.runArguments, true);
			this.closeRunArgumentsDialog();
		},
		openRunArgumentsDialog() {
			this.showRunArgumentsDialog = true;
		},
		closeRunArgumentsDialog() {
			this.showRunArgumentsDialog = false;
		},
		openSelectCoreDialog() {
			this.showSelectCoreDialog = true;
		},
		closeSelectCoreDialog() {
			this.showSelectCoreDialog = false;
		},

		// * data sources
		async getDataSources() {
			return await dsUtil.formatDataSourcesPayload(
				this.mountConfig?.ResourceID,
				this.mountConfig?.ResourceType,
				this.mountConfig?.Location
			);
		},
		setMountConfig(mountConfig: LocalMountConfig) {
			this.editorStore.setEditorMountConfig(mountConfig, true);
			this.closeMountDataSourcesDialog();
		},
		openMountDataSourcesDialog() {
			this.showMountDataSourcesDialog = true;
		},
		closeMountDataSourcesDialog() {
			this.showMountDataSourcesDialog = false;
		},
	},
	computed: {
		...mapState(useRootStore, {
			codeLanguagesList: "codeLanguagesList",
		}),
		...mapState(useEditorStore, {
			editorCore: "editorCoreObject",
			mountConfig: "mountConfig",
			editorCode: "editorSourceCode",
			editorTheme: "editorThemeID",
			runArguments: "editorRunArgs",
		}),
		...mapState(useCoresStore, {
			coreList: "filteredCoresList",
		}),
		layoutChangeButtonEnabled() {
			if (this.$vuetify.breakpoint.xs) {
				return false;
			} else if (this.$vuetify.breakpoint.sm) {
				return false;
			} else if (this.variableViewerVisibility && (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg)) {
				return false;
			} else {
				return true;
			}
		},
	},
	created() {
		// @ts-ignore FIXME: Currently not working
		// this.codeLanguage = JSON.parse(localStorage.getItem("editor-language")) || this.codeLanguagesList[0];
		this.coresStore.getCoresFilteredCoresByUserTier();
	},
	async mounted() {
		util.waitFor(() => this.editorCode !== undefined).then(() => {
			this.codeBuffer = this.editorCode as string;
			this.checkForCodeChangesAndSync(this.editorCode);
		});
		await this.editorStore.syncPreferredTheme()
	},
});
