
import { defineComponent } from "vue";

// Components
import LoadingCard from "@/components/LoadingCard.vue";
import SigCloudStorageFileDeleteDialog from "@/components/DataSources/SigCloudStorageFileDeleteDialog.vue";
import SigCloudStorageFileUploadDialog from "@/components/DataSources/SigCloudStorageFileUploadDialog.vue";
import SigCloudStorageCreateDirectoryDialog from "@/components/DataSources/SigCloudStorageCreateDirectoryDialog.vue";
import EditValueDialog from "@/components/DataSources/EditValueDialog.vue";
import FileViewer from "@/components/DataSources/FileViewer.vue";
import TooltipButton from "@/components/Common/TooltipButton.vue";

// Utilities
import * as signaloidClient from "@/js/signaloidClient";
import * as dsUtil from "@/components/DataSources/utilities";
import { monitoringCaptureError } from "@/plugins/monitoring";

// Libraries
import axios from "axios";

// Types
import { FileTree } from "@/types/general";

type ComponentData = {
	dataFileList: undefined;
	dataFileTree: undefined | FileTree[];
	fileListBuffer: any[];
	sourceOpenTrees: any[];
	destinationOpenTrees: any[];
	sourcePath: FileTree[];
	destinationPath: FileTree[];
	loadingFiles: boolean;
	selectForDownload: boolean;
	fileMoveMode: boolean;
	showEditPathDialog: boolean;
	editPathData: undefined | FileTree;
	showDeleteConfirmDialog: boolean;
	deletePathData: undefined | FileTree;
	showUploadDialog: boolean;
	uploadDestinationPath: undefined | string;
	fileBeingDownloaded: undefined | string;
	showCreateDirectoryDialog: boolean;
	createDirectoryData: undefined | string;
	showFileViewer: boolean;
	fileBeingOpened: undefined | string;
	fileViewerFileText: undefined | string;
};

export default defineComponent({
	name: "SigCloudStorageFileList",
	components: {
		LoadingCard,
		EditValueDialog,
		SigCloudStorageFileDeleteDialog,
		SigCloudStorageFileUploadDialog,
		SigCloudStorageCreateDirectoryDialog,
		TooltipButton,
		FileViewer,
	},
	props: {
		showDialog: { type: Boolean, default: false },
		showTitle: { type: Boolean, default: true },
		flatCard: { type: Boolean, default: false },
		minWidth: { type: String, default: "10px" },
		minHeight: { type: String, default: "50px" },
		inEditor: { type: Boolean, default: false },
	},
	data: (): ComponentData => ({
		dataFileList: undefined,
		dataFileTree: undefined,
		fileListBuffer: [],
		sourceOpenTrees: [],
		destinationOpenTrees: [],
		sourcePath: [],
		destinationPath: [],
		loadingFiles: false,
		selectForDownload: false,
		fileMoveMode: false,

		showEditPathDialog: false,
		editPathData: undefined,

		showDeleteConfirmDialog: false,
		deletePathData: undefined,

		showUploadDialog: false,
		uploadDestinationPath: undefined,
		fileBeingDownloaded: undefined,

		showCreateDirectoryDialog: false,
		createDirectoryData: undefined,

		showFileViewer: false,
		fileBeingOpened: undefined,
		fileViewerFileText: undefined,
	}),
	setup() {
		return { util: dsUtil };
	},
	computed: {
		validMove() {
			if (this.sourcePath[0].type && this.destinationPath[0].type) {
				if (
					(this.sourcePath[0].type == "dir" && this.destinationPath[0].type == "dir") ||
					(this.sourcePath[0].type != "dir" && this.destinationPath[0].type == "dir")
				) {
					return true;
				}
			}
			return false;
		},
	},
	methods: {
		closeDialog() {
			this.$emit("update:showDialog", false);
		},
		async getUserFiles() {
			try {
				this.loadingFiles = true;
				const response = await signaloidClient.getFiles();

				this.dataFileTree = dsUtil.pathListToDirectoryTree(
					response.data.items.map((item) => {
						return item.path;
					})
				);
			} catch (error) {
				monitoringCaptureError(error, "Fetch user files list in cloud storage");
			} finally {
				this.loadingFiles = false;
			}
		},
		validateMove() {
			// currently not used
			console.log(
				"src",
				JSON.stringify(this.sourcePath[0].path),
				"dst",
				JSON.stringify(this.destinationPath[0].path)
			);
		},
		async copyFiles() {
			// currently not used
			this.validateMove();
			if (this.validMove) {
				try {
					const response = await signaloidClient.copyUserFiles(
						this.sourcePath[0].path,
						this.destinationPath[0].path
					);
				} catch (error) {
					monitoringCaptureError(error, "Copy files in user cloud storage");

					if (axios.isAxiosError(error)) {
						if (error.request) {
							//no response
							console.log(error.request);
							throw new Error("Please check your internet connection");
						}
					}
				}
			}
		},
		async downloadFile(file: Pick<FileTree, "name" | "path">) {
			this.fileBeingDownloaded = file.path;
			try {
				const downloadURL = await signaloidClient.getFileDownloadURL(file.path);
				// based on https://www.delftstack.com/howto/javascript/javascript-download/
				if (downloadURL) {
					const response = await axios.get(downloadURL, { responseType: "blob" });
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", file.name);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
			} catch (error) {
				monitoringCaptureError(error, "Download files from user cloud storage");
			} finally {
				this.fileBeingDownloaded = undefined;
			}
		},
		async openFileInFileViewer(file: FileTree) {
			this.fileBeingOpened = file.path;
			try {
				const downloadURL = await signaloidClient.getFileDownloadURL(file.path);
				if (downloadURL) {
					const response = await axios.get(downloadURL, { responseType: "blob" });
					const reader = new FileReader();
					const fileBlob = new Blob([response.data]);

					this.fileViewerFileText = await fileBlob.text();
					this.showFileViewer = true;
				}
			} catch (error) {
				monitoringCaptureError(error, "Open file from user cloud storage");
				this.fileBeingOpened = undefined;
			}
		},
		downloadFileRequestHandler(filePath: string) {
			const file = {
				path: filePath,
				name: filePath.split("/").slice(-1)[0],
			};
			this.downloadFile(file);
		},
		closeFileViewer() {
			this.showFileViewer = false;
			this.fileBeingOpened = undefined;
			this.fileViewerFileText = undefined;
		},
		moveFiles() {
			// currently not used
			this.validateMove();
			// this.copyFiles()
			// this.deleteFile()

			// if (this.validMove) {
			// }
		},
		toggleMoveMode() {
			// currently not used
			this.fileMoveMode = !this.fileMoveMode;
			this.sourcePath = [];
			this.destinationPath = [];
		},
		openEditPathDialog(file: FileTree) {
			// currently not used
			this.showEditPathDialog = true;
			this.editPathData = file;
		},
		closeEditPathDialog() {
			this.showEditPathDialog = false;
			this.editPathData = undefined;
		},
		updateFileName(newPathName: string) {
			this.getUserFiles();
			this.closeEditPathDialog();
		},

		openDeletePathDialog(file: FileTree) {
			this.showDeleteConfirmDialog = true;
			this.deletePathData = file;
		},
		closeDeletePathDialog() {
			this.showDeleteConfirmDialog = false;
			this.deletePathData = undefined;
		},
		deleteSuccessfulCallback() {
			this.$emit("delete-successful");
			this.getUserFiles();
		},
		openUploadDialog(item?: FileTree) {
			if (!item || item.type == "dir") {
				this.showUploadDialog = true;
				this.uploadDestinationPath = item?.path;
			} else {
				console.warn("Tried to open upload dialog with a file destination");
				this.showUploadDialog = false;
				this.uploadDestinationPath = undefined;
			}
		},
		closeUploadDialog() {
			this.showUploadDialog = false;
			this.uploadDestinationPath = undefined;
		},
		uploadSuccessfulCallback() {
			this.$emit("upload-successful");
			this.getUserFiles();
		},
		openCreateDirectoryDialog(item?: FileTree) {
			this.showCreateDirectoryDialog = true;
			this.createDirectoryData = item?.path ? item.path : "";
		},
		closeCreateDirectoryDialog() {
			this.showCreateDirectoryDialog = false;
			this.createDirectoryData = undefined;
		},
	},
	mounted() {
		this.getUserFiles();
	},
});
