var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass:
        "pa-2 pa-sm-3 mx-0 font-weight-regular text-sm-body-1 text-body-2",
      staticStyle: { "overflow-y": "visible" },
      attrs: {
        rounded: "lg",
        outlined: "",
        "min-width": "300px",
        "data-test": "connected-repository-card",
      },
    },
    [
      _vm.repository && _vm.repoDetailsFromRepoHost
        ? _c(
            "div",
            { staticClass: "connected-card-grid" },
            [
              _c(
                "v-row",
                {
                  staticClass: "grid-title",
                  attrs: { "no-gutters": "", align: "center" },
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-truncate" },
                    [
                      _vm.repoDetailsFromRepoHost.visibility == "private"
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "primary" },
                            },
                            [_vm._v("mdi-book-lock")]
                          )
                        : _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v("mdi-book"),
                          ]),
                      _c("div", { staticStyle: { display: "inline" } }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.repoDetailsFromRepoHost.html_url,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.repoDetailsFromRepoHost.full_name) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.repoConfigFileData &&
                  _vm.repoConfigFileWarnings.length === 0
                    ? _c(
                        "v-col",
                        { staticClass: "mr-2", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", "open-delay": "500" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b({}, "span", attrs, false),
                                            on
                                          ),
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: `${_vm.repository.RemoteURL}/blob/${_vm.repository.Branch}/${_vm.repoConfigFilePath}`,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "20" } },
                                                  [
                                                    _vm._v(
                                                      " mdi-file-cog-outline "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2531111427
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " Detected repository configuration file '" +
                                    _vm._s(_vm.repoConfigFilePath) +
                                    "'. "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm.repoConfigFileData &&
                      _vm.repoConfigFileWarnings.length != 0
                    ? _c(
                        "v-col",
                        { staticClass: "mr-2", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                top: "",
                                "open-delay": "500",
                                "max-width": "600px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b({}, "span", attrs, false),
                                            on
                                          ),
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: `${_vm.repository.RemoteURL}/blob/${_vm.repository.Branch}/${_vm.repoConfigFilePath}`,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: "20",
                                                      color: "warning",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-file-alert-outline "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                901982663
                              ),
                            },
                            [
                              _c(
                                "span",
                                [
                                  _vm._v(
                                    " Detected repository configuration file with warnings: "
                                  ),
                                  _c("br"),
                                  _vm._l(
                                    _vm.repoConfigFileWarnings,
                                    function (warning) {
                                      return _c("span", { key: warning }, [
                                        _vm._v(" " + _vm._s(warning) + " "),
                                        _c("br"),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm.repoConfigFileError
                    ? _c(
                        "v-col",
                        { staticClass: "mr-2", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", "open-delay": "500" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b({}, "span", attrs, false),
                                            on
                                          ),
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: `${
                                                    _vm.repository.RemoteURL
                                                  }/blob/${
                                                    _vm.repository.Branch
                                                  }/${_vm.repoConfigFilePath}${
                                                    _vm.repoConfigFileError
                                                      .linePos &&
                                                    _vm.repoConfigFileError
                                                      .linePos.length > 0
                                                      ? "#L" +
                                                        _vm.repoConfigFileError
                                                          .linePos[0].line
                                                      : ""
                                                  }`,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: "20",
                                                      color: "error",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-file-alert-outline"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4272764165
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " Our system encountered a YAML error while parsing the configuration file " +
                                    _vm._s(_vm.repoConfigFilePath) +
                                    ". Please make sure the file is valid YAML. You can find more details of the error encountered during the parsing, below. "
                                ),
                                _c("br"),
                                _vm._v(" Error: "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { "white-space": "pre-line" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.repoConfigFileError.message)
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.$vuetify.breakpoint.mobile
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "float-left mx-0",
                              attrs: { outlined: "", small: "" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.repoDetailsFromRepoHost.visibility)
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.uiRepositoryDetailMode === false
                    ? [
                        _c("v-hover", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ hover }) {
                                  return [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "mx-2",
                                        attrs: { cols: "auto" },
                                      },
                                      [
                                        _c(
                                          "v-fade-transition",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  top: "",
                                                  "open-delay": "500",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-progress-circular",
                                                            {
                                                              staticStyle: {
                                                                position:
                                                                  "absolute",
                                                                top: "8px",
                                                                right: "16px",
                                                              },
                                                              attrs: {
                                                                indeterminate:
                                                                  _vm
                                                                    .repoDisconnectStatus
                                                                    .loading,
                                                                color: "danger",
                                                                width: _vm
                                                                  .repoDisconnectStatus
                                                                  .loading
                                                                  ? 2
                                                                  : 0,
                                                              },
                                                            }
                                                          ),
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "transition-slow-in-slow-out",
                                                                  class: hover
                                                                    ? "mx-0"
                                                                    : "mx-1",
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    size: hover
                                                                      ? "24"
                                                                      : "16",
                                                                    color:
                                                                      hover ||
                                                                      _vm
                                                                        .repoDisconnectStatus
                                                                        .loading
                                                                        ? "danger"
                                                                        : "green",
                                                                    "data-test":
                                                                      "disconnect-repo-button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.disconnectRepository,
                                                                  },
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-connection "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Disconnect"),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            608846327
                          ),
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "grid-description" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "text-md-body-2 text-caption truncate-lines",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.repoDetailsFromRepoHost.description) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "text-body-2 mt-1 ml-0",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _vm.repoDetailsFromRepoHost.topics
                            ? [
                                _vm._l(
                                  _vm.repoDetailsFromRepoHost.topics.slice(
                                    0,
                                    3
                                  ),
                                  function (topic) {
                                    return _c(
                                      "v-chip",
                                      {
                                        key: topic,
                                        staticClass: "mx-0",
                                        attrs: {
                                          outlined: "",
                                          "x-small": "",
                                          color: "info",
                                        },
                                      },
                                      [_vm._v(_vm._s(topic))]
                                    )
                                  }
                                ),
                                _vm.repoDetailsFromRepoHost.topics.slice(3)
                                  .length > 0
                                  ? _c(
                                      "span",
                                      { staticClass: "text-caption" },
                                      [
                                        _vm._v(
                                          " + " +
                                            _vm._s(
                                              _vm.repoDetailsFromRepoHost.topics.slice(
                                                3
                                              ).length
                                            ) +
                                            " more "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "grid-config",
                  attrs: {
                    "no-gutters": "",
                    align: "center",
                    justify: "end",
                    "align-content": "start",
                  },
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "max-height": "300px", "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: menu, attrs }) {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "", "open-delay": "250" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on: tooltip }) {
                                            return [
                                              _c(
                                                "LoadableChip",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        loading:
                                                          _vm.branchUpdateStatus
                                                            .loading,
                                                        chipIcon:
                                                          "mdi-source-branch",
                                                        chipText:
                                                          _vm.repository
                                                            .Branch ??
                                                          "Default",
                                                        color: _vm.repository
                                                          .Branch
                                                          ? "primary"
                                                          : "grey",
                                                        loadErrorState:
                                                          !_vm
                                                            .branchUpdateStatus
                                                            .loading &&
                                                          _vm.branchUpdateStatus
                                                            .error,
                                                        "data-test":
                                                          "branch-selector-chip",
                                                      },
                                                    },
                                                    "LoadableChip",
                                                    attrs,
                                                    false
                                                  ),
                                                  { ...tooltip, ...menu }
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [_vm._v("Working Branch")]),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.repository.Branch) +
                                          " @ " +
                                          _vm._s(
                                            _vm.repository.Commit.slice(0, 8)
                                          )
                                      ),
                                    ]),
                                    _vm.branchUpdateStatus.error
                                      ? _c("div", [
                                          _vm._v(
                                            "- " +
                                              _vm._s(
                                                _vm.branchUpdateStatus.message
                                              )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4258153991
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(
                          _vm.repositoryBranchesResponse,
                          function (branch) {
                            return _c(
                              "v-list-item",
                              {
                                key: branch.name,
                                attrs: { link: "", dense: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setWorkingBranch(branch.name, "")
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item-title",
                                  {
                                    staticClass:
                                      "ma-0 pa-0 text-body-2 font-weight-regular",
                                  },
                                  [_vm._v(_vm._s(branch.name))]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "", "open-delay": "250" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: tooltip }) {
                              return [
                                _c("LoadableChip", {
                                  attrs: {
                                    loading: _vm.coreUpdateStatus.loading,
                                    "chip-icon": "mdi-memory",
                                    "chip-text": _vm.activeCore?.Name,
                                    "chip-icon-color":
                                      _vm.coreChipStyle.textColor,
                                    "text-color": _vm.coreChipStyle.textColor,
                                    color: _vm.coreChipStyle.color,
                                    outlined: _vm.coreChipStyle.outlined,
                                    "load-error-state":
                                      !_vm.coreUpdateStatus.loading &&
                                      _vm.coreUpdateStatus.error,
                                    "data-test": "core-selector-chip",
                                  },
                                  on: { click: _vm.openSelectCoreDialog },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        559747958
                      ),
                    },
                    [_c("span", [_vm._v("Active Core")])]
                  ),
                  _c("v-menu", {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on: menu, attrs }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", "open-delay": "250" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on: tooltip }) {
                                          return [
                                            _c(
                                              "LoadableChip",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      loading:
                                                        _vm
                                                          .buildDirectoryUpdateStatus
                                                          .loading,
                                                      chipIcon:
                                                        "mdi-folder-account-outline",
                                                      chipText:
                                                        _vm.buildDirectoryPrettyString,
                                                      showMaxTextTail: true,
                                                      loadErrorState:
                                                        !_vm
                                                          .buildDirectoryUpdateStatus
                                                          .loading &&
                                                        _vm
                                                          .buildDirectoryUpdateStatus
                                                          .error,
                                                      "data-test":
                                                        "build-directory-selector-chip",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.openDirectoryTree,
                                                    },
                                                  },
                                                  "LoadableChip",
                                                  attrs,
                                                  false
                                                ),
                                                { ...tooltip, ...menu }
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Build Directory")])]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3864109786
                    ),
                  }),
                  _c("v-menu", {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on: menu, attrs }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", "open-delay": "250" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on: tooltip }) {
                                          return [
                                            _c(
                                              "LoadableChip",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      loading:
                                                        _vm
                                                          .runArgumentsUpdateStatus
                                                          .loading,
                                                      chipIconColor: _vm
                                                        .repository.Arguments
                                                        ? "primary"
                                                        : "grey",
                                                      iconOnly: true,
                                                      loadErrorState:
                                                        !_vm
                                                          .runArgumentsUpdateStatus
                                                          .loading &&
                                                        _vm
                                                          .runArgumentsUpdateStatus
                                                          .error,
                                                      chipIcon: "mdi-console",
                                                      "data-test":
                                                        "run-argument-selector-chip",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.openRunArgumentsDialog,
                                                    },
                                                  },
                                                  "LoadableChip",
                                                  attrs,
                                                  false
                                                ),
                                                { ...tooltip, ...menu }
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Command Line Arguments")])]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      766760807
                    ),
                  }),
                  _c("v-menu", {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on: menu, attrs }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", "open-delay": "250" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on: tooltip }) {
                                          return [
                                            _c(
                                              "LoadableChip",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      loading:
                                                        _vm
                                                          .dataSourceUpdateStatus
                                                          .loading,
                                                      chipIconColor:
                                                        _vm.dataSourceChipStyle
                                                          .color,
                                                      iconOnly: true,
                                                      chipIcon:
                                                        _vm.dataSourceChipStyle
                                                          .icon,
                                                      loadErrorState:
                                                        !_vm
                                                          .dataSourceUpdateStatus
                                                          .loading &&
                                                        _vm
                                                          .dataSourceUpdateStatus
                                                          .error,
                                                      "data-test":
                                                        "data-source-selector-chip",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.openMountDataSourcesDialog,
                                                    },
                                                  },
                                                  "LoadableChip",
                                                  attrs,
                                                  false
                                                ),
                                                { ...tooltip, ...menu }
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c("span", [_vm._v("Data Sources")]),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      "State: " +
                                        _vm._s(_vm.dataSourceChipStyle.tooltip)
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      679562495
                    ),
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "text-body-2 mt-1 ml-1 grid-footer",
                  attrs: { "no-gutters": "", align: "end" },
                },
                [
                  _c(
                    "v-col",
                    [
                      !_vm.$vuetify.breakpoint.mobile
                        ? _c("div", [
                            _vm.repoDetailsFromRepoHost.license
                              ? _c(
                                  "div",
                                  { staticClass: "mr-3 text-caption" },
                                  [
                                    _c("v-icon", { attrs: { size: "18" } }, [
                                      _vm._v("mdi-scale-balance"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.repoDetailsFromRepoHost.license
                                            .name
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "d-inline-flex" }, [
                              _vm.repoDetailsFromRepoHost.forks_count
                                ? _c(
                                    "div",
                                    { staticClass: "mr-3 text-caption" },
                                    [
                                      _c("v-icon", { attrs: { size: "18" } }, [
                                        _vm._v("mdi-source-fork"),
                                      ]),
                                      _vm._v(
                                        _vm._s(
                                          _vm.repoDetailsFromRepoHost
                                            .forks_count
                                        ) + " "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.repositoryBranchesResponse
                                ? _c(
                                    "div",
                                    { staticClass: "mr-3 text-caption" },
                                    [
                                      _c("v-icon", { attrs: { size: "18" } }, [
                                        _vm._v("mdi-source-branch"),
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.repositoryBranchesResponse
                                              .length
                                          ) +
                                          " "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "mr-3 text-caption" },
                                [
                                  _c("v-icon", { attrs: { size: "18" } }, [
                                    _vm._v("mdi-update"),
                                  ]),
                                  !_vm.$vuetify.breakpoint.mobile
                                    ? [_vm._v(" Last updated: ")]
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatDate(
                                          _vm.repoDetailsFromRepoHost.updated_at
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      !_vm.$vuetify.breakpoint.mobile
                        ? [
                            _c(
                              "v-col",
                              {
                                staticStyle: { padding: "0" },
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-inline-flex justify-start",
                                    staticStyle: { "align-items": "end" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "font-weight-medium" },
                                      [_vm._v("Repository ID:")]
                                    ),
                                    _c(
                                      "code",
                                      {
                                        staticClass:
                                          "text-caption font-weight-regular truncated",
                                        staticStyle: {
                                          display: "inline",
                                          "margin-left": "0.3em",
                                          padding: "0.1em 0.6em",
                                        },
                                        attrs: {
                                          "data-test":
                                            "task-card-task-id-value",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.repositoryID))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "16px",
                                          "margin-bottom": "1em",
                                        },
                                      },
                                      [
                                        _c("CopyToClipboardButton", {
                                          attrs: {
                                            "value-to-copy": _vm.repositoryID,
                                            "value-name": "Repository ID",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        : [
                            _c(
                              "v-col",
                              {
                                staticStyle: { padding: "0" },
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-inline-flex justify-start",
                                    staticStyle: { "align-items": "end" },
                                  },
                                  [
                                    _c("v-icon", [_vm._v("mdi-identifier")]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "16px",
                                          "margin-bottom": "1em",
                                        },
                                      },
                                      [
                                        _c("CopyToClipboardButton", {
                                          attrs: {
                                            "value-to-copy": _vm.repositoryID,
                                            "value-name": "Repository ID",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                    ],
                    2
                  ),
                  _vm.uiRepositoryDetailMode === false
                    ? [
                        _c("div", { staticClass: "mx-1" }),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("TooltipButton", {
                              attrs: {
                                color: "light-blue lighten-3",
                                width: _vm.$vuetify.breakpoint.mobile
                                  ? "50px"
                                  : "64px",
                                "min-width": "0px",
                                disabled:
                                  !_vm.activeTask ||
                                  (_vm.activeTask.Status &&
                                    _vm.isTaskActive(_vm.activeTask.Status)),
                                btnIcon: "mdi-inbox-arrow-up",
                                tooltipText: "Open Execution Log",
                                "data-test": "open-execution-log-button",
                              },
                              on: { click: _vm.openExecutionLog },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c("div", { staticClass: "mx-1" }),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("TooltipButton", {
                        attrs: {
                          color: "primary",
                          width: _vm.$vuetify.breakpoint.mobile
                            ? "50px"
                            : "64px",
                          "min-width": "0px",
                          href: `https://github.dev/${_vm.repoDetailsFromRepoHost.full_name}`,
                          target: "_blank",
                          exact: true,
                          btnIcon: "mdi-microsoft-visual-studio-code",
                          tooltipText: "Edit on github.dev",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.uiRepositoryDetailMode === false
                    ? [
                        _c("div", { staticClass: "mx-1" }),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("TooltipButton", {
                              attrs: {
                                color: "accent",
                                width: _vm.$vuetify.breakpoint.mobile
                                  ? "50px"
                                  : "64px",
                                tooltipText: "Discover Variables",
                                "data-test": "repo-details-button",
                              },
                              on: { click: _vm.openRepositoryDetailsPage },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "btnContent",
                                    fn: function () {
                                      return [
                                        _c("v-icon", [
                                          _vm._v("mdi-text-box-search-outline"),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1298009901
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c("div", { staticClass: "mx-1" }),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("LimitableActionButton", {
                        attrs: {
                          color: "accent",
                          width: _vm.$vuetify.breakpoint.mobile
                            ? "75px"
                            : "100px",
                          loading: _vm.activeTask?.Status
                            ? _vm.isTaskActive(_vm.activeTask.Status)
                            : false,
                          tooltipText: "Compile and Run",
                          "data-test": "build-and-run-button",
                          "limits-to-check": [
                            _vm.UserLimitsE.ConcurrentTaskCount,
                            _vm.UserLimitsE.DynamicInstructionCount,
                          ],
                          "btn-icon": "mdi-play",
                        },
                        on: { "valid-click": _vm.submitTaskRequest },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "grid-buildBar" },
                [
                  _c(
                    "v-expand-transition",
                    [
                      _c("BuildBar", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showBuildBar,
                            expression: "showBuildBar",
                          },
                        ],
                        attrs: {
                          taskID: _vm.activeTask?.TaskID,
                          taskStatus: _vm.activeTask?.Status,
                          taskAccessState: _vm.taskAccessState,
                          activeTask: _vm.activeTask,
                        },
                        on: { "cancel-task": _vm.cancelActiveTask },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-truncate" },
                    [
                      _c("v-icon", { staticClass: "mr-1" }, [
                        _vm._v("mdi-book-search"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.repoPlaceholderName) + " "),
                    ],
                    1
                  ),
                  _vm.uiRepositoryDetailMode === false
                    ? [
                        _c("v-hover", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ hover }) {
                                  return [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "mx-2",
                                        attrs: { cols: "auto" },
                                      },
                                      [
                                        _c(
                                          "v-fade-transition",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  top: "",
                                                  "open-delay": "500",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "transition-slow-in-slow-out",
                                                                  class: hover
                                                                    ? "mx-0"
                                                                    : "mx-1",
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    size: hover
                                                                      ? "24"
                                                                      : "16",
                                                                    color: hover
                                                                      ? "danger"
                                                                      : "green",
                                                                    "data-test":
                                                                      "disconnect-repo-button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.disconnectRepository,
                                                                  },
                                                                },
                                                                "v-icon",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "mdi-connection "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Disconnect"),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2965948419
                          ),
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm.repoAccessState.error
                ? [
                    _c(
                      "v-alert",
                      {
                        staticClass:
                          "d-flex justify-center mt-2 mb-0 mx-auto px-2 text-body-2",
                        attrs: {
                          outlined: "",
                          width: "99%",
                          type: "warning",
                          dense: "",
                          border: "left",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.repoAccessState.message) + " ")]
                    ),
                  ]
                : [
                    _c("v-skeleton-loader", {
                      staticClass: "mb-n4",
                      attrs: { type: "list-item" },
                    }),
                    _c("v-skeleton-loader", {
                      staticClass: "my-n2",
                      attrs: { type: "actions" },
                    }),
                  ],
            ],
            2
          ),
      _vm.showRunArgumentsDialog
        ? _c("RunArgumentsDialog", {
            attrs: {
              showDialog: _vm.showRunArgumentsDialog,
              initialRunArguments: _vm.repository?.Arguments,
            },
            on: {
              "close-dialog": _vm.closeRunArgumentsDialog,
              "set-arguments": _vm.setRunArguments,
            },
          })
        : _vm._e(),
      _vm.showSelectCoreDialog
        ? _c("SelectCoreDialog", {
            attrs: {
              showDialog: _vm.showSelectCoreDialog,
              minimumCore: _vm.repoConfigFileData?.MinimumCore,
              activeCore: _vm.activeCore,
            },
            on: {
              "close-dialog": _vm.closeSelectCoreDialog,
              "set-core": _vm.setActiveCoreID,
            },
          })
        : _vm._e(),
      _vm.showDirectoryDialog
        ? _c("RepositoryBuildDirectoryDialog", {
            attrs: {
              showDialog: _vm.showDirectoryDialog,
              "selected-directory-path": _vm.selectedBuildDirectory,
              "repository-full-name": _vm.repositoryFullName,
              "sha-name": _vm.repository?.Branch ?? _vm.repository?.Commit,
            },
            on: {
              "update:showDialog": function ($event) {
                _vm.showDirectoryDialog = $event
              },
              "update:show-dialog": function ($event) {
                _vm.showDirectoryDialog = $event
              },
              "update:selectedDirectoryPath":
                _vm.selectedBuildDirectoryUpdateHandler,
            },
          })
        : _vm._e(),
      _vm.showMountDataSourcesDialog
        ? _c("MountDataSourcesDialog", {
            attrs: {
              showDialog: _vm.showMountDataSourcesDialog,
              initialMountConfig: _vm.chosenDataSources
                ? _vm.chosenDataSources[0]
                : undefined,
            },
            on: {
              "close-dialog": _vm.closeMountDataSourcesDialog,
              "set-data-sources": _vm.setMountConfig,
              "reset-data-sources": _vm.setMountConfig,
              "remove-data-sources": _vm.setMountConfig,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }